import styles from './Copyright.module.scss';

export default function Copyright() {
  return (
    <div className={styles.licenseWrapper}>
      <p className={styles.copyrightText}>
        © 2024 PricewaterhouseCoopers GmbH Wirtschaftsprüfungsgesellschaft. All rights reserved. PwC refers to the PwC network and/or one or more of its member firms, each of which is a separate legal entity. Please see <a className={styles.networkLink} href="https://www.pwc.com/structure" target="_blank" rel="noreferrer">www.pwc.com/structure</a> for further details. Portions of this program may use third-party open source components governed by the <a className={styles.licenseLink} href="/license.txt" target="_blank" rel="noreferrer">respective open source license terms</a>.
      </p>      
    </div>
  );
}