import { useEffect, useState } from 'react';

import { useCalculatorStore } from '../../../../../../stores/CalculatorStore';
import StepHeader from '../shared/StepHeader/StepHeader';
import styles from './CalculationResult.module.scss';
import { useShallow } from 'zustand/react/shallow';
import { LandFormData, calculationBase } from '../../../../../../types/Land';
import { getLocaleNumberWithFixedDecimalPoint } from '../../../../../../helpers/NumberHelper';

// Step 5
const stepIndex = 5;
const title = 'Beihilfewert in €';
const info = `Sie haben nun den Beihilfewert der Bürgschaft für Ihren Kredit berechnet. Die Höhe des Beihilfewertes kann Auswirkungen auf die Höhe von Investitionszuschüssen in Regionalfördergebieten bzw. auf die Höhe von Fördermitteln, die unter die "De-minimis"-Verordnung fallen, haben.`;

export default function CalculationResult() {  
  const [selectedLand, setSelectedLand] = useState<LandFormData>();
  const [formData, calculationResult, isLoading, setResult] = useCalculatorStore(
    useShallow(state => [state.formData, state.calculationResult, state.isLoading, state.setResult]));

  useEffect(() => {
    setSelectedLand(
      formData.guaranteeCommission.lands.find(l => l.landCode === formData.guaranteeCommission.selectedLandCode)
    );
    const getResult = async () => await setResult();
    getResult();
  }, [formData, setResult]);

  return (
    <div className={styles.calculationResult}>
      <StepHeader stepIndex={stepIndex} title={title} info={info} />
      {!isLoading() && <div className={styles.calculationResultWrapper}>
        <div className={styles.sumBox}>
          <p className={styles.sumLabel}>Ihr Beihilfewert beträgt</p>
          <h2 className={styles.sumValue}>{getLocaleNumberWithFixedDecimalPoint(calculationResult.result, 2)} €</h2>
        </div>
        <div className={styles.sumDetail}>
          <p>Datum der Berechnung: {formData.guaranteeRating.referenceDate.date.toLocaleDateString('fr-CH')}</p>
          <p>Beihilfewert Ergebnis: {getLocaleNumberWithFixedDecimalPoint(calculationResult.result, 2)} €</p>
          <p>Ratingkategorie: {formData.guaranteeRating.guaranteeRate.class}</p>
          <p>Bürgschaftsprovision: {selectedLand?.provision} %</p>
          <p>Berechnungsbasis: {calculationBase.find(b => b.id === selectedLand?.calculationBase)?.name}</p>
          <p>Recovery Rate: {getLocaleNumberWithFixedDecimalPoint(formData.recoveryRate.recoveryRate, 2)} %</p>
          <p>Discounting Rate: {getLocaleNumberWithFixedDecimalPoint(calculationResult.discountRate, 3)} %</p>
          <p>Kreditverlauf:</p>
          <div className={styles.creditDetail}>
            {formData.creditHistoryAndGuaranteeRatio.creditHistory.map((h, index) => (
              <p key={index}>Jahr {index + 1}: {h.creditStanding.value} € | Bürgschaftsquote: {h.guaranteeQuota.value} % </p>
            ))}
          </div>
        </div>
      </div>}
    </div>
  )
}
