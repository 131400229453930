import { ReactNode, useState } from 'react';

import styles from './StepHeader.module.scss'

type StepHeaderProps = {
  stepIndex: number
  title: string
  info: string
  children?: ReactNode
}

export default function StepHeader({ stepIndex, title, info, children }: StepHeaderProps) {
  const [isInfoOpen, setIsInfoOpen] = useState<boolean>(true);

  return (
    <header className={styles.stepHeader}>
      <section className={styles.titleWrapper}>
        <div className={styles.title}>Step {stepIndex}: {title}</div>
        <div
          className={`Appkit4-icon icon-information-outline no-print ${styles.infoButton} ${isInfoOpen && styles.infoOpened}`}          
          aria-hidden="true"
          onClick={() => setIsInfoOpen(!isInfoOpen)}>
        </div>
      </section>
      {children && children}
      {isInfoOpen && <p className={styles.infoText}>{info}</p>}
    </header>
  )
}
