import { Stepper, Steppers } from '@appkit4/react-components'

import styles from './CalculatorHeader.module.scss'
import { useCalculatorStore } from '../../../../stores/CalculatorStore';

export default function CalculatorHeader() {
  const stepIndex: number = useCalculatorStore(state => state.stepIndex);

  return (
    <header className={`no-print ${styles.headerWrapper}`}>
      <Steppers className={styles.stepperStyle} activeIndex={stepIndex - 1}>
        <Stepper status='normal'></Stepper>
        <Stepper status='normal'></Stepper>
        <Stepper status='normal'></Stepper>
        <Stepper status='normal'></Stepper>
        <Stepper status='normal'></Stepper>
      </Steppers>
    </header>
  );
}
