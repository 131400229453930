import { useEffect } from 'react';

import styles from './Calculator.module.scss';

import CalculatorHeader from './components/header/CalculatorHeader';
import CalculatorBody from './components/body/CalculatorBody';
import CalculatorFooter from './components/footer/CalculatorFooter';
import CalculatorLoader from './components/CalculatorLoader/CalculatorLoader';
import { useCalculatorStore } from '../../stores/CalculatorStore';

export default function Calculator() { 
  const setConfig: Function = useCalculatorStore(state => state.setConfig);

  useEffect(() => {   
    const getConfig = async () => await setConfig();
    getConfig();
  });

  return (
    <div className={styles.calculatorWrapper}>
      <CalculatorHeader />
      <CalculatorBody />
      <CalculatorFooter />
      <CalculatorLoader />
    </div>
  );  
}
