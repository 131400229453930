import { useShallow } from 'zustand/react/shallow';

import { Button } from '@appkit4/react-components/button';

import styles from './CalculatorFooter.module.scss';
import { useCalculatorStore } from '../../../../stores/CalculatorStore';
import Copyright from './Copyright/Copyright';

export default function CalculatorFooter() {
  const [stepIndex, resetCalculator, goNextStep, goPrevStep]: [number, Function, Function, Function] =
    useCalculatorStore(
      useShallow(state => [state.stepIndex, state.resetCalculator, state.goNextStep, state.goPrevStep])
    );

  function print() {
    window.print();
  }

  return (
    <footer className={`no-print ${styles.footerWrapper}`}>
      <div className={styles.buttonWrapper}>
        {stepIndex !== 1 && <Button className={`${styles.footerButton} ${styles.backButton}`} onClick={goPrevStep}>Zurück</Button>}
        {stepIndex !== 5 && <Button className={`${styles.footerButton} ${styles.nextButton}`} onClick={goNextStep}>Weiter</Button>}
        {stepIndex === 5 && <Button className={`${styles.footerButton} ${styles.resetButton}`} onClick={resetCalculator}>Neu Berechnen</Button>}
        {stepIndex === 5 && <Button className={`${styles.footerButton} ${styles.printButton}`} onClick={print}>Drucken</Button>}
      </div>
      <div className={styles.copyrightWrapper}>
        <Copyright />
      </div>
    </footer>
  );
}
