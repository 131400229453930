import { getNumberFromLocaleNumberString } from '../../../../../../helpers/NumberHelper';
import { useCalculatorStore } from '../../../../../../stores/CalculatorStore';
import { CreditHistoryAndGuaranteeRatioFormData } from './CreditHistoryAndGuaranteeRatioFormData';

export const creditHistoryAndGuaranteeRatioValidation = (formData: CreditHistoryAndGuaranteeRatioFormData) => {
  const setCreditHistory = useCalculatorStore.getState().setCreditHistory;
  let isValid = true;

  for (let i =0; i < formData.creditHistory.length; i++) {
    if (formData.creditHistory[i].creditStanding.error || formData.creditHistory[i].guaranteeQuota.error) {
      isValid = false;
    }

    if (isValidValue(formData.creditHistory[i].creditStanding.value)) {
      setCreditHistory('creditStanding', i, formData.creditHistory[i].creditStanding.value);
      isValid = false;
    }
  }

  return isValid;
};

const isValidValue = (value: string): boolean => (
  value === '' || getNumberFromLocaleNumberString(value) <= 0
);