import {
  createBrowserRouter,
  Navigate,
  RouterProvider,
} from 'react-router-dom';

import Calculator from './pages/calculator/Calculator';

const router = createBrowserRouter([
  {
    path: '/',
    element: <Calculator />,
  },
  {
    path: '*',    
    element: <Navigate to="/" replace={true} />
  }
]);

function App() {
  return (
    <RouterProvider router={router} fallbackElement={<p>Initial Load...</p>} />
  );
}

export default App;
