import styles from './CalculatorBody.module.scss'

import CreditHistoryAndGuaranteeRatio from './steps/CreditHistoryAndGuaranteeRatio/CreditHistoryAndGuaranteeRatio'
import GuaranteeRating from './steps/GuaranteeRating/GuaranteeRating'
import RecoveryRate from './steps/RecoveryRate/RecoveryRate'
import Result from './steps/CalculationResult/CalculationResult'
import GuaranteeCommission from './steps/GuaranteeCommission/GuaranteeCommission'
import { useCalculatorStore } from '../../../../stores/CalculatorStore'

export default function CalculatorBody() {
  const stepIndex: number = useCalculatorStore(state => state.stepIndex);

  return (
    <section className={styles.stepWrapper}>
      {stepIndex === 1 && <GuaranteeRating />}
      {stepIndex === 2 && <CreditHistoryAndGuaranteeRatio />}
      {stepIndex === 3 && <GuaranteeCommission />}
      {stepIndex === 4 && <RecoveryRate />}
      {stepIndex === 5 && <Result />}
    </section>
  );
}
  