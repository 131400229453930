import { Loading } from '@appkit4/react-components';

import styles from './CalculatorLoader.module.scss';
import { useCalculatorStore } from '../../../../stores/CalculatorStore';
import { CalculatorLoader as CalculatorLoaderType } from '../../../../types/CalculatorLoader';

export default function CalculatorLoader() {
  const loader: CalculatorLoaderType = useCalculatorStore(state => state.loader);

  return (
    <>
      {loader.isLoading &&
        <div className={styles.calculatorLoaderWrapper}>
          <Loading loadingType='circular' indeterminate={true} compact={false} circularWidth="60px" style={{ marginTop: '19%' }} />
          <p className={styles.loadingMessage}>{loader.message}</p>
        </div>
      }
    </>
  );
}
