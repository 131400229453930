import { getNumberFromLocaleNumberString } from "../helpers/NumberHelper";
import { CalculatorFormData } from "../types/CalculatorFormData";
import { LandFormData } from "../types/Land";
import { InitialConfigRes } from "./interfaces/InitialConfigRes";
import { ResultReq } from "./interfaces/ResultReq";
import { ResultRes } from "./interfaces/ResultRes";

const defaultHeader = { "Accept": "application/json" };

export const getConfig = async (): Promise<InitialConfigRes> => {
  const res = await fetch('/Calculator/GetConfig', { headers: defaultHeader });
  return await res.json();
};

export const getResult = async (formData: CalculatorFormData): Promise<ResultRes> => {
  const res = await fetch('/Calculator/GetResult', {
    method: 'POST',
    headers: {
      ...defaultHeader,
      "Content-Type": "application/json"
    },
    body: JSON.stringify(getResultReqFromFormData(formData))
  });

  return await res.json();
};

const getResultReqFromFormData = (formData: CalculatorFormData): ResultReq => {
  const selectedLand: LandFormData =
    formData.guaranteeCommission.lands.find(l => l.landCode === formData.guaranteeCommission.selectedLandCode)!;

  const d = formData.guaranteeRating.referenceDate.date;
  const dateWithoutTimezone = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));

  return {
    guaranteeRating: {
      date: dateWithoutTimezone,
      ratingCategory: formData.guaranteeRating.guaranteeRate.class
    },
    CreditHistoryAndGuaranteeRatios: formData.creditHistoryAndGuaranteeRatio.creditHistory.map(h =>
      ({ amount: getNumberFromLocaleNumberString(h.creditStanding.value), guaranteeRate: getNumberFromLocaleNumberString(h.guaranteeQuota.value) })),
    guaranteeCommission: {
      ...selectedLand,
      provision: getNumberFromLocaleNumberString(selectedLand.provision),
      calculationBase: selectedLand.calculationBase
    },
    recoveryRate: formData.recoveryRate.recoveryRate
  };  
}
