export type Land = {
  landCode: string;
  landName: string;
  provision: number;
  calculationBase: number;
  sortOrder: number;
};

export type LandFormData = Omit<Land, 'provision'> & {
  provision: string;
  error: boolean;
  errorMessage: string;
};

/**
 * Weitere which means `the other land` in English is not stored in Database.
 */
export const otherLand: Land = {
  landCode: 'weitere',
  landName: 'Weitere',
  provision: 0,
  calculationBase: 1,
  sortOrder: 9999
};

export type CalculationBase = {
  id: number;
  name: string;
};

/**
 * CalculationBase is not stored in Database so we have to use a hard-coded list.
 */
export const calculationBase: CalculationBase[] = [
  { id: 1, name: 'Kreditbetrag' },
  { id: 2, name: 'Bürgschaftsbetrag' },
];
