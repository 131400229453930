import { useState } from 'react';

import StepHeader from '../shared/StepHeader/StepHeader';
import styles from './GuaranteeCommission.module.scss';
import { Input, Radio, RadioGroup } from '@appkit4/react-components';
import { CalculationBase, calculationBase as defaultCalculationBase } from '../../../../../../types/Land';
import { GuaranteeCommissionFormData } from './GuaranteeCommissionFormData';
import { useCalculatorStore } from '../../../../../../stores/CalculatorStore';
import { useShallow } from 'zustand/react/shallow';
import { replaceBrToLineBreak } from '../../../../../../helpers/StringHelper';

// Step 3
const stepIndex = 3;
const title = 'Bürgschaftsprovision';
const info = `Die Höhe der vom Kreditnehmer zu entrichtenden Bürgschaftsprovision kann sowohl nach Maßgabe der Richtlinien des staatlichen Bürgen als auch einzelfallbezogen variieren. Berechnungsbasis für die Bürgschaftsprovision ist entweder der Kreditbetrag oder der Bürgschaftsbetrag (Kreditbetrag x Bürgschaftsquote). Die nachstehende Übersicht bietet eine Auswahl an staatlichen Bürgen mit den üblichen Provisionssätzen, die Sie bitte entsprechend auswählen. Für Bürgschaften anderer staatlicher Bürgen sind die Angaben zu Provisionssatz und Berechnungsbasis den jeweiligen Programmen zu entnehmen und hier manuell zu erfassen.`;
const landNamesRequireBase = ['Weitere'];

export default function GuaranteeCommission() {
  const [calculationBase,] = useState<CalculationBase[]>(defaultCalculationBase);
  const [formData, setSelectedLand, setLandProvision, setLandCalculationBase]:
    [GuaranteeCommissionFormData, Function, Function, Function] = useCalculatorStore(useShallow(state => [
      state.formData.guaranteeCommission,
      state.setSelectedLand,
      state.setLandProvision,
      state.setLandCalculationBase
    ]));

  function onProvisionChange(value: string, landIndex: number): void {
    setLandProvision(landIndex, value);
  }

  return (
    <div className={styles.guaranteeCommision}>
      <StepHeader stepIndex={stepIndex} title={title} info={info} />
      <div className={styles.guaranteeCommisionInputWrapper}>        
        <ul className={styles.guaranteeCommisionList}>
          <li className={styles.guaranteeCommisionRow}>
            <div className={styles.radioColumn}></div>
            <div className={styles.landColumn}>Bürge</div>
            <div className={styles.provisionColumn}>Bürgschaftsprovision p.a. in %</div>
            <div className={styles.calculationBaseColumn}>Berechnungsbasis<br />Bürgschaftsprovision p.a.</div>
          </li>
          {formData.lands.length > 0 &&
            formData.lands.map((land, landIndex) => (
              <li key={landIndex} className={`calculator-list-hover ${styles.guaranteeCommisionRow}`} onClick={_ => setSelectedLand(land.landCode)}>
                <div className={styles.radioColumn}><Radio value={land.landCode} name="land" checked={formData.selectedLandCode === land.landCode}/></div>                
                <div className={styles.landColumn}>{replaceBrToLineBreak(land.landName)}</div>
                <div className={styles.provisionColumn}>
                  <Input
                    type={'text'}
                    inputMode="numeric"
                    value={land.provision}
                    hideTitleOnInput={true}
                    error={land.error}
                    errorNode={<p className="inputError">{land.errorMessage}</p>}                    
                    className="calculator-input calculator-input-cursor-right"
                    onChange={(value: string) => onProvisionChange(value, landIndex)}
                    />
                </div>
                <div className={styles.calculationBaseColumn}>
                  {!landNamesRequireBase.includes(land.landName) ?
                    calculationBase.find(b => b.id === land.calculationBase)!.name :
                    <RadioGroup defaultValue={land.calculationBase} name="calculationBase">
                      {calculationBase.map(base => (
                        <Radio
                          key={base.id}
                          value={base.id}
                          onClick={() => setLandCalculationBase(landIndex, base.id)}
                        >{base.name}</Radio>
                      ))}
                    </RadioGroup>
                  }
                </div>
              </li>              
            ))
          }
        </ul>
      </div>
      <div className={styles.additionalInfoWrapper}>
        <p className={styles.infoText}>(*) Vor dem 09.06.2016 ist Berechnungsbasis für die Bürgschaftsprovision der Kreditbetrag. Für die Berechnung von Beihilfewerten vor diesem Datum nutzen Sie bitte die Position "Weitere" in der Liste und geben Sie als Bürgschaftsprovision 0,75 ein und wählen Sie anschließend als Berechnungsbasis "Kreditbetrag".</p>
      </div>
    </div>
  )
}
