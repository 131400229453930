import { useState } from 'react';

import { useShallow } from 'zustand/react/shallow';

import { Radio, RadioGroup } from '@appkit4/react-components';

import StepHeader from '../shared/StepHeader/StepHeader';
import styles from './RecoveryRate.module.scss';
import { useCalculatorStore } from '../../../../../../stores/CalculatorStore';
import { RecoveryRateOption, recoveryRateOptions } from './RecoveryRateOption';

// Step 4
const stepIndex = 4;
const title = 'Recovery Rate';
const info = `Die Recovery Rate (Sicherheitserlösquote) drückt als standardisierter Prozentwert aus, in welchem Umfang die erwartete Inanspruchnahme einer staatlichen Bürgschaft durch Sicherheitenverwertung, Insolvenzquote o.a. reduziert wird. Die Recovery Rate beträgt für Investitionskredite generell 20 %. Für Betriebsmittelkredite beträgt die Recovery Rate 12,5 %. Ausnahme: Für Mecklenburg-Vorpommern gilt hier unabhängig von der Kreditverwendung ein Wert von 12,5 %.`;

export default function RecoveryRate() {
  const [recoveryRates,] = useState(recoveryRateOptions);
  const [recoveryRate, setRecoveryRate]: [RecoveryRateOption, Function] =
    useCalculatorStore(useShallow(state => [
      state.formData.recoveryRate,
      state.setRecoveryRate
    ]));

  return (
    <div className={styles.recoveryRate}>
      <StepHeader stepIndex={stepIndex} title={title} info={info} />
      <p className={styles.inputLabel}>Bitte wählen Sie die für Sie gültige Recovery Rate aus.</p>
      <div className={styles.recoveryRateInputWrapper}>        
        <div className={styles.recoveryRateGroup}>
          <label className={styles.inputLabel}>Recovery rate</label>
          <RadioGroup defaultValue={recoveryRate.id} name="recoveryRate">
            {recoveryRates.map(option => (
              <Radio
                key={option.id}
                value={option.id}
                onClick={() => setRecoveryRate(option)}
              >{option.recoveryRate} %</Radio>  
            ))}
          </RadioGroup>
        </div>
      </div>
    </div>
  );
}
