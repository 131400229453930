import { useEffect } from 'react';

import { Input, Select } from '@appkit4/react-components';

import StepHeader from '../shared/StepHeader/StepHeader';
import styles from './CreditHistoryAndGuaranteeRatio.module.scss';
import { useCalculatorStore } from '../../../../../../stores/CalculatorStore';
import { useShallow } from 'zustand/react/shallow';
import { formatNumber } from '../../../../../../helpers/NumberHelper';

// Step 2
const stepIndex = 2;
const title = 'Kreditverlauf und Bürgschaftsquote';
const info = `Bitte geben Sie in der Tabelle für Jahr 1 den maximalen Auszahlungsbetrag (Erstvalutierung) und im Folgenden den weiteren Kreditverlauf - Kreditstand jeweils zum 01.01. des betreffenden Laufzeitjahres - ein.

Die Bürgschaftsquote gibt an, in welcher prozentualen Höhe der (Rest-)Kreditbetrag durch die staatliche Bürgschaft besichert ist. Gemäß den Vorgaben der Europäischen Kommission ist hier ein Maximalwert von 90 % zulässig.
Die Höhe der Bürgschaftsquote kann bis zu diesem Maximalwert einzelfallbezogen auch über die Kreditlaufzeit variieren. Sie können daher die Bürgschaftsquote in der Tabelle gemäß den Bürgschaftsfestlegungen für Ihren Kredit in den einzelnen Laufzeitjahren gegebenenfalls anpassen.`;

export default function CreditHistoryAndGuaranteeRatio() {
  const dropdownData = Array(24).fill(undefined).map((_, i) => ({ value: i + 1, label:  i + 1 }));

  const [formData, setCreditYear, setCreditHistory] = useCalculatorStore(useShallow(state => [
      state.formData.creditHistoryAndGuaranteeRatio,
      state.setCreditYear,
      state.setCreditHistory
    ]));

  useEffect(() => {
    if (formData.creditYear === 0)
      setCreditYear(1);
  });

  function onCreditStandingChange(value: string, index: number) {
    setCreditHistory('creditStanding', index, value);
  }

  function onCreditStandingBlur(event: Event, index: number) {
    const value: string = (event!.target as HTMLInputElement).value;
    if (value !== '')
      setCreditHistory('creditStanding', index, formatNumber(value));
  }

  function onGuaranteeQuota(value: string, index: number) {
    setCreditHistory('guaranteeQuota', index, value);
  }

  return (
    <div className={styles.creditHistoryWrapper}>
      <StepHeader stepIndex={stepIndex} title={title} info={info}>
        <p style={{ 'textAlign': 'center' }}>Bitte geben Sie zunächst die Laufzeit des verbürgten Kredits in vollen Jahren (ggf. aufrunden) an.</p>
        <div style={{ 'display': 'flex', 'justifyContent': 'center', 'padding': '.5rem 0' }}>
          <Select
            data={dropdownData}
            value={formData.creditYear}
            style={{ width: '20rem' }}
            placeholder="Kreditlaufzeit in Jahren"
            onSelect={vals => setCreditYear(vals as number)}
          />
        </div>
      </StepHeader>
      <div className={styles.creditHistoryInputWrapper}>
        <ul className={styles.creditHistoryList}>
          <li className={styles.creditHistoryRow}>
            <div className={styles.yearColumn}>Jahr</div>
            <div className={styles.creditStandingColumn}>Kreditstand</div>
            <div className={`${styles.guaranteeQuotaColumn}`}>
              Bürgschaftquote&nbsp;<p className={styles.highlightText}>(Max. 90%)</p>
            </div>
          </li>
          {formData.creditHistory.length > 0 && formData.creditHistory.map((item, index) => (
            <li className={styles.creditHistoryRow} key={index}>
              <div className={styles.yearColumn}>{index + 1}</div>
              <div className={styles.creditStandingColumn}>
                <Input
                  type={'text'}
                  inputMode="numeric"
                  value={item.creditStanding.value}
                  hideTitleOnInput={true}
                  error={item.creditStanding.error}
                  errorNode={<p className="inputError">{item.creditStanding.errorMessage}</p>}
                  className={`calculator-input calculator-input-cursor-right ${styles.inputGrow}`}
                  onChange={(value: string) => onCreditStandingChange(value, index)}
                  onBlur={(e: Event) => onCreditStandingBlur(e, index)} />&nbsp;€
              </div>
              <div className={styles.guaranteeQuotaColumn}>
                <Input
                  type={'text'}
                  inputMode="numeric"
                  value={item.guaranteeQuota.value}
                  hideTitleOnInput={true}
                  error={item.guaranteeQuota.error}
                  errorNode={<p className="inputError">{item.guaranteeQuota.errorMessage}</p>}
                  className={`calculator-input calculator-input-cursor-right ${styles.inputGrow}`}
                  onChange={(value: string) => onGuaranteeQuota(value, index)}
                  />&nbsp;%
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}
