export const getLocaleNumberWithFixedDecimalPoint = (value: number, fixture: number, locale="de-DE") =>
  Number(value).toLocaleString(locale, { minimumFractionDigits: fixture, maximumFractionDigits: fixture });

export const getNumberFromLocaleNumberString = (value: string): number => {
  const thousandSeparator = '.';
  const decimalPoint = ',';
  return parseFloat(value.replaceAll(thousandSeparator, '').replace(decimalPoint, '.'));
}

export const formatNumber = (value: string): string => {
  let ohnepunkte;
  let getrennt;
  let anzahl;
  let start;
  let fertig;
  let i;

  //alte Punkte entfernen
  ohnepunkte = value.replace(/\./g, "");

  //dezimalstellen vom Ganzzahl trennen
  getrennt = ohnepunkte.split(",");

  //wieviele 3er Abschnitte
  anzahl = Math.floor(getrennt[0].length / 3);

  //wieviele Zeichen noch davor
  start = getrennt[0].length - anzahl * 3;

  //Zeichen davor ins Zielzeichenkette schreiben
  fertig = getrennt[0].substr(0, start);

  //jeden 3er-Block mit Punkt an Zielzeichenkette anh?ngen
  for (i = 1; i <= anzahl; i++) {
      //Wenn 3er-Bl?cke genau aufgehen, keinen Punkt an Anfang 
      if (!(i === 1 && start === 0))
          fertig += '.';
      //Anfangswert f?r den jeweiligen 3er-Block 
      var anfang = start + (i - 1) * 3;
      //jeweiligen 3er-Bock an Zeilzeichenkette anh?ngen 
      fertig += getrennt[0].substr(anfang, 3);
  }

  //Dezimalabschnitt an Zielzeichenkette anh?ngen
  if (getrennt.length > 1) {
      fertig += ',' + getrennt[1];
  }
  else {
      fertig += ',00';
  }
  // feld.value = fertig;
  return fertig;
}