import { create } from 'zustand';
import { produce } from 'immer';

import { CalculatorConfig } from '../types/CalculatorConfig';
import { GuaranteeRatingFormData } from '../pages/calculator/components/body/steps/GuaranteeRating/GuaranteeRatingFormData';
import { guaranteeRatingOptions } from '../pages/calculator/components/body/steps/GuaranteeRating/GuaranteeRatingOptions';
import { isGuaranteeRatingValid } from '../pages/calculator/components/body/steps/GuaranteeRating/GuaranteeRatingValidation';
import { creditHistoryAndGuaranteeRatioValidation } from '../pages/calculator/components/body/steps/CreditHistoryAndGuaranteeRatio/CreditHistoryAndGuaranteeRatioValidation';
import { otherLand } from '../types/Land';
import { RecoveryRateOption, recoveryRateOptions } from '../pages/calculator/components/body/steps/RecoveryRate/RecoveryRateOption';
import { CalculatorLoader } from '../types/CalculatorLoader';
import { getConfig, getResult } from '../api/CalculatorApi';
import { CalculatorFormData } from '../types/CalculatorFormData';
import { CalculatorResult } from '../types/CalculatorResult';
import { guaranteeCommissionValidation } from '../pages/calculator/components/body/steps/GuaranteeCommission/GuaranteeCommissionValidation';
import { getLocaleNumberWithFixedDecimalPoint, getNumberFromLocaleNumberString } from '../helpers/NumberHelper';
import { isValidNumber } from '../validators/NumberValidators';

export interface CalculatorState {
  stepIndex: number;
  loader: CalculatorLoader;
  config: CalculatorConfig;
  formData: CalculatorFormData;
  calculationResult: CalculatorResult;
}

export interface CalculatorAction {
  goNextStep: () => void;
  goPrevStep: () => void;
  setLoader: (loader: CalculatorLoader) => void;
  isLoading: () => boolean;
  setConfig: () => Promise<void>;
  resetCalculator: () => void;  
  setGuaranteeRating: (guaranteeRatingFormData: GuaranteeRatingFormData) => void;
  setCreditYear: (year: number) => void;
  setCreditHistory: (key: string, index: number, value: string) => void;
  setSelectedLand: (landId: string) => void;
  setLandProvision: (index: number, provision: string) => void;
  setLandCalculationBase: (index: number, calculationBase: number) => void;
  setRecoveryRate: (recoveryRate: RecoveryRateOption) => void;  
  setResult: () => Promise<void>;
}

const calculatorInitialState: CalculatorState = {
  stepIndex: 1,
  loader: { isLoading: false, message: '' },
  config: {
    // Default binding.
    defaultBuergschaftsQuote: 80,
    maxBuergschaftsQuote: 90,
    minReferenceDate: new Date(2007,8,26), //'2007/09/26'
  },
  //
  formData: {
    // Step 1
    guaranteeRating: {
      guaranteeRate: guaranteeRatingOptions[0],
      referenceDate: {
        date: new Date(),
        error: false
      },
    },
    // Step 2
    creditHistoryAndGuaranteeRatio: {
      creditYear: 0,
      creditHistory: []
    },
    // Step 3
    // Should get initial data from backend
    guaranteeCommission: {
      lands: [],
      selectedLandCode: ''
    },
    //
    // Step 4
    recoveryRate: recoveryRateOptions[0]
  },
  calculationResult: {
    result: 0,
    discountRate: 0
  }
};

export const useCalculatorStore = create<CalculatorState & CalculatorAction>()((set, get) => ({
  ...calculatorInitialState,
  goNextStep: () => {
    const currentStep = get().stepIndex;

    if (currentStep < 5) {
      if (currentStep === 1 && !isGuaranteeRatingValid(get().formData.guaranteeRating))
        return;
      if (currentStep === 2 &&
          !creditHistoryAndGuaranteeRatioValidation(get().formData.creditHistoryAndGuaranteeRatio))
        return;
      if (currentStep === 3 && !guaranteeCommissionValidation(get().formData.guaranteeCommission))
        return;
      set({ stepIndex: currentStep + 1});
    }
  },
  goPrevStep: () => {
    const currentStep = get().stepIndex;
    if (currentStep > 1)
      set({ stepIndex: currentStep - 1});
  },
  setLoader: (loader: CalculatorLoader) => set({ loader }),
  isLoading: (): boolean => get().loader.isLoading,
  setConfig: async () => {
    get().setLoader({ isLoading: true, message: 'Loading Calculator configuration...' });
    const config = await getConfig();

    set(produce(state => {
      state.config = {
        defaultBuergschaftsQuote: config.defaultBuergschaftsQuote,
        maxBuergschaftsQuote: config.maxBuergschaftsQuote,
        minReferenceDate: new Date(config.minDate)
      };
      state.formData.guaranteeCommission = {
        lands: [...config.lands, otherLand].map(l =>
          ({...l, provision: getLocaleNumberWithFixedDecimalPoint(l.provision, 3) })),
        selectedLandCode: config.defaultLandCode
      }
    }));

    get().setLoader({ isLoading: false, message: '' });
  },
  resetCalculator: async () => {
    set(calculatorInitialState);
    await get().setConfig();
  },
  setGuaranteeRating: (guaranteeRatingFormData: GuaranteeRatingFormData)  => {
    set(
      produce(state => { state.formData.guaranteeRating = guaranteeRatingFormData })
    );
  },
  setCreditYear: (year: number) => {
    set(
      produce(state => {
        state.formData.creditHistoryAndGuaranteeRatio.creditYear = year;
        state.formData.creditHistoryAndGuaranteeRatio.creditHistory =
          Array(year).fill(undefined).map(_ => ({
            creditStanding: { value: '', error: false },
            guaranteeQuota: { value: getLocaleNumberWithFixedDecimalPoint(get().config.defaultBuergschaftsQuote, 2), error: false }
          }));
      })
    );
  },
  setCreditHistory: (key: string, index: number, value: string) => {
    let error = false;
    let errorMessage = '';

    if (!isValidNumber(value)) {
      error = true;
      errorMessage = value === '' ? 'Value must not be null' : 'Value must be a number';
    }  

    if (getNumberFromLocaleNumberString(value) <= 0) {
      error = true;
      errorMessage = 'Value must be greater than 0';
    }

    if (key === 'guaranteeQuota' && getNumberFromLocaleNumberString(value) > 90) {
      error = true;
      errorMessage = 'Value must be between 0 and 90%';
    }
    
    set(
      produce(state => {
        state.formData.creditHistoryAndGuaranteeRatio.creditHistory[index][key] = { value, error, errorMessage };
      })
    );
  },
  setSelectedLand: (landCode: string) => {
    set(produce(state => {
      state.formData.guaranteeCommission.selectedLandCode = landCode;
    }));
  },
  setLandProvision: (index: number, provision: string) => {
    let error = false;
    let errorMessage = '';

    if (!isValidNumber(provision)) {
      error = true;
      errorMessage = 'Value must be a number';
    }    

    if (getNumberFromLocaleNumberString(provision) > 100) {
      error = true;
      errorMessage = 'Value must be less than 100.';
    }

    set(produce(state => {      
      state.formData.guaranteeCommission.lands[index].provision = provision;
      state.formData.guaranteeCommission.lands[index].error = error;
      state.formData.guaranteeCommission.lands[index].errorMessage = errorMessage;
    }));
  }, 
  setLandCalculationBase: (index: number, calculationBase: number) => {
    set(produce(state => {
      state.formData.guaranteeCommission.lands[index].calculationBase = calculationBase;
    }));
  },
  setRecoveryRate: (recoveryRate: RecoveryRateOption) => {
    set(produce(state => {
      state.formData.recoveryRate = recoveryRate;
    }));
  },
  setResult: async () => {
    set({ loader: { isLoading: true, message: 'Calculating the Aids value...' }});
    set({ calculationResult:  await getResult(get().formData) });
    set({ loader: { isLoading: false, message: '' }});
  }
}));
