import { useState } from 'react';
import { useShallow } from 'zustand/react/shallow';

import { CalendarPicker } from '@appkit4/react-components/datepicker';
import { Radio } from '@appkit4/react-components'

import { guaranteeRatingOptions } from './GuaranteeRatingOptions';
import StepHeader from '../shared/StepHeader/StepHeader';
import styles from './GuaranteeRating.module.scss';
import { GuaranteeRatingFormData } from './GuaranteeRatingFormData';
import { useCalculatorStore } from '../../../../../../stores/CalculatorStore';
import { getLocaleNumberWithFixedDecimalPoint } from '../../../../../../helpers/NumberHelper';

// Step 1
const stepIndex = 1;
const title = "Bürgschaftsrating";

export default function GuaranteeRating() {
  const [today,] = useState<Date>(new Date());
  const [maxDate,] = useState<Date>(new Date(today.getFullYear(), 11, 31));
  const [formData, minDate, setGuaranteeRating]: [GuaranteeRatingFormData, Date, Function] =
    useCalculatorStore(useShallow(state => [
      state.formData.guaranteeRating,
      state.config.minReferenceDate,
      state.setGuaranteeRating
    ]));

  const info = `Bitte ordnen Sie die dem Rating Ihres Kreditgebers entsprechende 1-Jahres-Ausfallwahrscheinlichkeit nach folgendem Muster einer der fünf Ratingkategorien für Bürgschaften zu. Wählen Sie Ihr so ermitteltes Bürgschaftsrating dann in der Liste aus und gehen Sie weiter zu Step 2.\n` +
    `Die Berechnung des Beihilfewertes ist auch für ein beliebiges Datum in der Vergangenheit (frühestens ${minDate.toLocaleDateString('fr-CH')}) mit dem jeweils entsprechenden EU-Referenzzinssatz möglich.`;

  function optionClicked(selectedClass: number) {
    setGuaranteeRating({ ...formData, guaranteeRate: guaranteeRatingOptions.find(o => o.class === selectedClass)! });
  }

  function onDateChange(date: Date | Date[], isValid: boolean): void {
    setGuaranteeRating({ ...formData, referenceDate: { date, error: !isValid } });
  }

  return (
    <div className={styles.guranteeRating}>
      <StepHeader stepIndex={stepIndex} title={title} info={info} />      
      <div className={styles.guranteeRatingInputWrapper}>
        <CalendarPicker
          className={styles.referenceDate}
          defaultValue={formData.referenceDate.date}
          minDate={minDate}
          maxDate={maxDate}
          format="DD.MM.YYYY"
          locale="de"
          fieldTitle="Referenzdatum"
          required
          onChange={onDateChange}
        />
        <ul className={styles.guaranteeRatingList}>
          <li className={styles.guaranteeRatingRow}>
            <div className={styles.radioColumn}></div>
            <div className={styles.rateColumn}>1-Jahres-Ausfall- wahrscheinlichkeit in %</div>
            <div className={styles.classColumn}>Ratingkategorien Bürgschaften</div>
          </li>
          {guaranteeRatingOptions.length > 0 &&
            guaranteeRatingOptions.map((option, index) => (
              <li key={index} className={`calculator-list-hover ${styles.guaranteeRatingRow}`} onClick={_ => optionClicked(option.class)}>
                <div className={styles.radioColumn}><Radio value={option.class} name="creditClass" checked={formData.guaranteeRate?.class === option.class}/></div>
                <div className={styles.rateColumn}>{`> ${getLocaleNumberWithFixedDecimalPoint(option.from, 2)}`} <br /> {`<= ${getLocaleNumberWithFixedDecimalPoint(option.to, 2)}`}</div>
                <div className={styles.classColumn}>{option.class}</div>
              </li>              
            ))
          }
        </ul>
      </div>
    </div>
  );
}
